import React, { useEffect, useState } from 'react';
import { Link } from "gatsby"
import ReactModal from 'react-modal'
import { Container, Box, Text, SuperHeading, ModuleCard, MediumBoldText, Section, Flex, Heading, SubheadSmall, Card, Header, Button, Subhead, FlexList, CardHeader, CardBody } from "../components/ui-sans"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout_registration"
import Seo from "../components/seo";
// import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import * as queryString from "query-string";
import axios from '../helpers/axios-config'
import { spancolor, video, Ideology_img, lightcolor_1, lightcolor_4, bold, marginTop, black_color, text_justify, marginBottom_4, marginBottom_5, marginTop_1, padding } from "../components/ui-sans";

const StudentRegistration = ({ location }) => {
  console.log("location...", location);
  const parsed = queryString.parse(location.search);
  const [college_id, setcollege_id] = useState(parsed && parsed.college_id ? Number(parsed.college_id) : 10004);//useQueryParam("college_id", StringParam);
  const [college_image, setCollegeImage] = useState(null)
  const [r_date, setR_Date] = useState(null)
  const [o_date, setO_Date] = useState(null)
  const [single_module_actual_fee, setsingle_module_actual_fee] = useState(null)
  const [single_module_discounted_fee, setsingle_module_discounted_fee] = useState(null)
  const [all_modules_fee, setall_modules_fee] = useState(null)
  const [all_modules_discounted_fee, setall_modules_discounted_fee] = useState(null)
  useEffect(() => {
    var d = new Date()
    axios.get("https://storage.googleapis.com/skillcdn/colleges/colleges_contact.json?v=" + d.getTime())
      .then(response => {
        setCollegeImage(null)
        var colleges = response.data.colleges
        console.log("response...", response.data.colleges)
        if (!college_image && college_id) {
          var college = colleges.find(p => p.code == college_id.toString())
          if (college) {
            setR_Date(college.r_date)
            setO_Date(college.o_date)
            setsingle_module_actual_fee(college.single_module_actual_fee)
            setsingle_module_discounted_fee(college.single_module_discounted_fee)
            setall_modules_fee(college.all_modules_fee)
            setall_modules_discounted_fee(college.all_modules_discounted_fee)
          }
          var img = `https://storage.googleapis.com/skillcdn/colleges/${college_id}.png?v=${d.getTime()}`
          setCollegeImage(img)
          formvalues.college_id = college_id
        }
      })
  }, [])
  const intialvalues = {
    name: "",
    email: "",
    semister: "",
    college: "",
    phone: "",
    course: "",
    module_opted: "",
    module_fee: "",
    college_id: college_id ?? 10004,
    message: ""
  }
  const [formvalues, setFormvalues] = useState(intialvalues)
  const [formErrors, setFormErrors] = useState({})
  const [issubmit, setIssubmit] = useState(false)
  const [readMore, setReadMore] = useState({
    bizzlab: false, programme: false
    , module1: false
    , module2: false
    , module3: false
    , module4: false
    , module5: false
  })
  const handleChange = (e) => {
    if (e.target.name == "module_opted") {
      formvalues.module_opted = e.target.value
      formvalues.module_fee = e.target.value == "Module 1" ? 1500 : 5000
      setFormvalues(formvalues);
    }
    else
      setFormvalues({ ...formvalues, [e.target.name]: e.target.value });
    setFormErrors({})
    setIssubmit(false)
    console.log(formvalues)
  }
  const Video = ({ videoSrcURL, videoTitle, ...props }) => (
    <div className={video} style={{ height: "250px" }}>
      <iframe height="250px"
        width="100%"
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  )
  const submithandler = (e) => {
    console.log("on submit")
    e.preventDefault()
    setFormErrors(validate(formvalues))

    if (formvalues.name !== "" && formvalues.phone !== "" && formvalues.email !== ""
      && formvalues.semister !== "" && formvalues.course !== ""
      && formvalues.module_opted !== ""
      && formvalues.module_fee !== "") {
      var formData = {
        name: formvalues.name, phone: formvalues.phone, email: formvalues.email,
        semister: formvalues.semister
        , course: formvalues.course
        , module_opted: formvalues.module_opted
        , module_fee: formvalues.module_fee
        , college: formvalues.college
        , college_id: college_id
        , message: formvalues.message
      }

      axios.post('/contact', formData).then((response) => {
        setIssubmit(true)

        setFormvalues(intialvalues);
      }).catch((error) => {
        if (error) {
          console.log(error)
        }
        else {
          alert("Please fill the all feilds")
        }
      })
    }
  }
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && issubmit) {
    }
  }, [formErrors])
  const validate = (values) => {
    const errors = {}
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    //const regexPhone = /^[0]?[789]\d{9}$/;
    const regexPhone = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

    if (!values.name) {
      errors.name = "Name is Required"
    }
    if (!values.email) {
      errors.email = "Email is required";
    }
    else if (!regex.test(values.email)) {
      errors.email = "This is not a valid format"
    }
    if (!values.phone) {
      errors.phone = "Mobile is required"
    }
    else if (!regexPhone.test(values.phone)) {
      errors.phone = "PhoneNumber is not valid format"
    }
    if (!values.course) {
      errors.course = "Course is required"
    }
    if (!values.semister) {
      errors.semister = "semister is required"
    }

    if (!values.module_opted) {
      errors.module_opted = "Please Confirm Module"
    }

    // if (!values.Message) {
    //   errors.Message = "Message is required"
    // }
    return errors;
  }

  return (
    <Layout navName="Student Feedback">
      <Seo title="Student Feedback" />
      <Container width="tight" >
        <Flex gap={4} style={{ marginTop: "10px" }}>
          <Box >
            {college_image && <img src={college_image} style={{ height: "40%", width: "30%", resizeMode: 'cover' }}></img>}

          </Box>
          <Box style={{ marginTop: "2px", justifyContent: "right", width: "45%" }}>
            <StaticImage src="../images/bizzlab_logo.png" alt="bizzlab" width={200} />
            <MediumBoldText>Business <br /> Laboratory</MediumBoldText>
          </Box>
        </Flex>
      </Container>
      <Container width="tight">
        <Heading className={marginTop_1}>Virtual Work Experience Programme at your College</Heading>
        <Text>in association with “Bizzlab”</Text>
        <MediumBoldText>A Web Platform for Job Simulation</MediumBoldText>
        <Flex gap={4}>
          <StaticImage src="../images/student_registration/banner_img-min.png" alt="banner" width={1000} />
        </Flex>
        <Heading>What is BIZZLAB?</Heading>
        <Text className={text_justify}>Bizzlab is a web-platform designed and intended for commerce students. It is a unique concept that offers actual industry job experience in digital way. Using this web platform commerce students and job seekers, can get practical work experience in accounting, tax, finance, business processes and statutory works in digital way without working in any industry. </Text>
        {readMore.bizzlab && <Text className={text_justify}>‘BizzLab’ is a revolutionary simulation software which offers industry job exposure in a Simulated manner to the college students. It means user get the same working knowledge as that of working in a company.  This platform strongly believe that experience of work can be gained even before working in the industry. ‘BizzLab’ is a true avenue for skill and employability enhancement for college students who are studying B.Com, BBA, BBM, M.Com, MBA(finance), CA, CS, CMA etc.</Text>}
        {!readMore.bizzlab ? <Button variant="feed_back" onClick={(e) => {
          e.preventDefault();
          setReadMore({ ...readMore, bizzlab: !readMore.bizzlab })
        }} >Read more</Button> :
          <Button variant="feed_back" onClick={(e) => {
            e.preventDefault();
            setReadMore({ ...readMore, bizzlab: !readMore.bizzlab })
          }}>Read Less</Button>}
        <SubheadSmall className={marginTop_1}>Bizzlab - Programme Highlights:</SubheadSmall>
        <FlexList>
          <li><StaticImage src="../images/student_registration/bullet.png" /></li>
          <li><Text>Designed to offer practical exposure</Text></li>
        </FlexList>
        <Text className={text_justify}>This programme is for all Commerce Students – B.Com/BBA/M.Com/MBA (finance) students, designed to offer practical exposure to college students.</Text>
        <FlexList>
          <li><StaticImage src="../images/student_registration/bullet.png" /></li>
          <li><Text>A software to simulate industry works</Text></li>
        </FlexList>
        <Text className={text_justify}> It is a Job-Simulation Software, where students will login to various virtual companies
          to experiment and learn industry works in online way. More than 100 mid-size virtual companies enabled to learn work. 10million accounting and financial transactions are enabled.</Text>
        <FlexList>
          <li><StaticImage src="../images/student_registration/bullet.png" /></li>
          <li><Text>A software to simulate industry works</Text></li>
        </FlexList>
        <Text className={text_justify}>Transactions   would   be   displayed   in   the   form   of   Bills,   Purchase   orders,   GRN, Invoices,   Receipts,   Payments,   Challans,   Statements   etc.   which   gives   realistic corporate feeling to the users.  </Text>
        {readMore.programme &&
          <>
            <FlexList>
              <li><StaticImage src="../images/student_registration/bullet.png" /></li>
              <li><Text>Self-learning portal</Text></li>
            </FlexList>
            <Text className={text_justify}>Every student after registration will be allotted an access to the platform. This is a self-learning portal and orientation will be given at your college. Practical exposure would be offered by way of Modules. </Text>
            <FlexList>
              <li><StaticImage src="../images/student_registration/bullet.png" /></li>
              <li><Text>Score Card & Work Experience Certificate</Text></li>
            </FlexList>
            <Text className={text_justify}>After each module completion, score card and certificate of work experience will be auto generated by system.</Text>
            <FlexList>
              <li><StaticImage src="../images/student_registration/bullet.png" /></li>
              <li><Text>Verified & Tested CV</Text></li>
            </FlexList>
            <Text className={text_justify}>After completion of six modules system will auto generate ‘Verified and Tested CV’ that will  show case your practical  learnings  with details.  Industry will  short list candidates based on this learning.</Text>
            <FlexList>
              <li><StaticImage src="../images/student_registration/bullet.png" /></li>
              <li><Text>Flexible Module selection</Text></li>
            </FlexList>
            <Text className={text_justify}>There is no mandatory rule to take up all modules. Certificate will be given to one Module also.</Text>
          </>
        }



        <Flex gap={4}>
          <StaticImage src="../images/student_registration/Programmehighlights.png" alt="banner" width={1000} />
        </Flex>

        {!readMore.programme ? <Button variant="feed_back" onClick={(e) => {
          e.preventDefault();
          setReadMore({ ...readMore, programme: !readMore.programme })
        }}>Read more</Button> :
          <Button variant="feed_back" onClick={(e) => {
            e.preventDefault();
            setReadMore({ ...readMore, programme: !readMore.programme })
          }}>Read Less</Button>}
        <Subhead>Programme Structure</Subhead>
        <Text>5 Top Areas of Industry Exposure</Text>
        <Text className={text_justify}>
          Bizzlab - virtual working environment has more than twenty different work modules such as ‘Book-Keeping and Accounting, Business Setup and registrations, Banking and financial literacy, Income tax, GST, Payroll, Statutory filing, BPO works, Budgeting, MIS, Analysis of financial statement and many more. However, your college has opted the programme as below:
        </Text>
        <MediumBoldText>Module - 1</MediumBoldText>
        <ModuleCard style={{ background: "linear-gradient(151.1deg, #001A57 28.85%, #03A1E2 143.73%)", borderRadius: "7px", marginTop: "5rem" }}>
          <StaticImage src="../images/student_registration/Module1_Laptop-min.png" style={{ position: "relative", top: "-67px" }} />
          <Text style={{ color: "#ffff", position: "relative", top: "-50px" }}>Book-Keeping & Accounting</Text>
          <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>This Module give complete accounting transactional exposure using live accounting records (bills, vouchers, invoices, PO, GRN etc) and explain you how to use accounting system.</Text>
          {readMore.module1 &&
            <>
              <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
                This module has 10 categories of top priority   areas   of   accounting   –   like Purchase & Direct Cost, Services &
                Expenses,   Fixed   Assets,   Salaries, Sales,     Receipts,    Payments, Borrowings, Loans, Taxes and other special transactions.
              </Text>
              <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
                Total 90 sub-categories of works have been designed.
              </Text>
              <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
                Every transaction would be displayed with details and user need to account it in the accounting system.
              </Text>
              <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
                Every transaction has help in the form Read theory, Instructions, Videos, Answers etc
              </Text>
              <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
                Every successful transaction system will award one mark.
              </Text>
              <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
                Every failure system shows where the mistake happened.
              </Text>
              <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
                User can access as many bills/transactions as possible.
              </Text>

              <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
                User need to login in to one Virtual company and account their transactions for 12 months.
              </Text>

              <Flex gap={4} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
                <Box  >
                  <Text>30 to 40 hours of online time spend is required</Text>

                </Box>
                <Box  >
                  <Text bold="bold" style={{ textDecoration: "line-through", color: "red" }}> Rs 5000</Text>
                  <Text bold="bold">  Rs 1500</Text>
                  <br />
                </Box>
              </Flex>
            </>
          }
          {!readMore.module1 ? <Button variant="reversed" onClick={(e) => {
            e.preventDefault();
            setReadMore({ ...readMore, module1: !readMore.module1 })
          }}
            style={{ position: "relative", top: "-40px" }}
          >Read more</Button> :
            <Button variant="reversed" style={{ position: "relative", top: "-40px" }} onClick={(e) => {
              e.preventDefault();
              setReadMore({ ...readMore, module1: !readMore.module1 })
            }}>Read Less</Button>}
        </ModuleCard>
        <MediumBoldText>Module - 2</MediumBoldText>
        <ModuleCard style={{ background: "linear-gradient(151.1deg, #001A57 28.85%, #03A1E2 143.73%)", borderRadius: "7px", marginTop: "5rem" }}>
          <StaticImage src="../images/student_registration/Module2.png" style={{ position: "relative", top: "-67px" }} />
          <Text style={{ color: "#ffff", position: "relative", top: "-50px" }}>Business Setup & Registration Works</Text>
          <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>This Module will explain about the procedure for various registrations and business setup works that are generally required in various companies and businesses.</Text>
          {readMore.module2 && <>
            <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              Platform is offering practical exposure in 10 registration works – such as Company Incorporation, DIN, PAN, TAN, PF, PT, ESI, IEC and Udyam Aadhar.
            </Text>
            <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              Each area practical data will be given using that user need to apply each registration.
            </Text>
            <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              Simulation of actual government website will be shown.
            </Text>
            <Flex gap={4} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              <Box  >
                <Text>Approx. 30 hours to complete</Text>

              </Box>
              <Box  >
                <Text bold="bold" style={{ textDecoration: "line-through", color: "red" }}> Rs 5000</Text>
                <Text bold="bold">  Rs 1500</Text>
                <br />
              </Box>
            </Flex>
          </>}

          {!readMore.module2 ? <Button variant="reversed" onClick={(e) => {
            e.preventDefault();
            setReadMore({ ...readMore, module2: !readMore.module2 })
          }}
            style={{ position: "relative", top: "-40px" }}
          >Read more</Button> :
            <Button variant="reversed" style={{ position: "relative", top: "-40px" }} onClick={(e) => {
              e.preventDefault();
              setReadMore({ ...readMore, module2: !readMore.module2 })
            }}>Read Less</Button>}
        </ModuleCard>
        <MediumBoldText>Module  - 3</MediumBoldText>
        <ModuleCard style={{ background: "linear-gradient(151.1deg, #001A57 28.85%, #03A1E2 143.73%)", borderRadius: "7px", marginTop: "5rem" }}>
          <StaticImage src="../images/student_registration/Module3.png" style={{ position: "relative", top: "-67px" }} />
          <Text style={{ color: "#ffff", position: "relative", top: "-50px" }}>E-Banking and Financial Literacy</Text>
          <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>This module explains about the online banking operations that are commonly required in each company.</Text>
          {readMore.module3 && <>
            <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              This module provides nine(9) important areas of banking and financial literacy viz Account Opening (Saving, Current, Forex Account, Demat Account), Fund Transfer ( RTGS, IMPS, NEFT), Forex Payments ( A1 Material payments, A2 Service Payments, Bill lodgements, Exports receipts, e-BRC, EDPS, IDMPS), Deposits (FD, RD and Closures), Credit Cards, Loans, Investments ( Mutual funds, NSC, PPF, NPF), Insurance, Negotiable Instruments (Bills of exchanges, Promissory notes), e-EYC
            </Text>
            <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              Live records and information will be provided with documents, using this user need to transact all the above areas.
            </Text>
            <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              Total 100 transactions would be required to be done across all nine categories of work
            </Text>
            <Flex gap={4} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              <Box  >
                <Text>Approx. 40 hours to complete</Text>

              </Box>
              <Box  >
                <Text bold="bold" style={{ textDecoration: "line-through", color: "red" }}> Rs 5000</Text>
                <Text bold="bold">  Rs 1500</Text>
                <br />
              </Box>
            </Flex>
          </>}

          {!readMore.module3 ? <Button variant="reversed" onClick={(e) => {
            e.preventDefault();
            setReadMore({ ...readMore, module3: !readMore.module3 })
          }}
            style={{ position: "relative", top: "-40px" }}
          >Read more</Button> :
            <Button variant="reversed" style={{ position: "relative", top: "-40px" }} onClick={(e) => {
              e.preventDefault();
              setReadMore({ ...readMore, module3: !readMore.module3 })
            }}>Read Less</Button>}
        </ModuleCard>
        <MediumBoldText>Module  - 4</MediumBoldText>
        <ModuleCard style={{ background: "linear-gradient(151.1deg, #001A57 28.85%, #03A1E2 143.73%)", borderRadius: "7px", marginTop: "5rem" }}>
          <StaticImage src="../images/student_registration/Module4_1.png" style={{ position: "relative", top: "-67px" }} />
          <Text style={{ color: "#ffff", position: "relative", top: "-50px" }}>Income Tax Practical Works</Text>
          <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>This module explains about the Income tax actual filing that are common in every company. This module offers simulated income tax site environment where users can do the work</Text>
          {readMore.module4 && <>
            <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              This module focuses on six (6) areas - Tax payments (TDS/TCS/Advance tax), E-TDS filing, Tax return (Salaries tax return), For 26AS reconciliation, Form-15CA filing, IT depreciation computation.
            </Text>
            <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              This module offers simulated income tax site environment where users can do the work.
            </Text>
            <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              Total 100 transactions would be required to be done across all nine categories of work.
            </Text>
            <Flex gap={4} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              <Box  >
                <Text>Approx. 35 hours to complete</Text>

              </Box>
              <Box  >
                <Text bold="bold" style={{ textDecoration: "line-through", color: "red" }}> Rs 5000</Text>
                <Text bold="bold">  Rs 1500</Text>
                <br />
              </Box>
            </Flex>
          </>}

          {!readMore.module4 ? <Button variant="reversed" onClick={(e) => {
            e.preventDefault();
            setReadMore({ ...readMore, module4: !readMore.module4 })
          }}
            style={{ position: "relative", top: "-40px" }}
          >Read more</Button> :
            <Button variant="reversed" style={{ position: "relative", top: "-40px" }} onClick={(e) => {
              e.preventDefault();
              setReadMore({ ...readMore, module4: !readMore.module4 })
            }}>Read Less</Button>}
        </ModuleCard>
        <Text>Module - 5</Text>
        <ModuleCard style={{ background: "linear-gradient(151.1deg, #001A57 28.85%, #03A1E2 143.73%)", borderRadius: "7px", marginTop: "5rem" }}>
          <StaticImage src="../images/student_registration/Module5_1.png" style={{ position: "relative", top: "-67px" }} />
          <Text style={{ color: "#ffff", position: "relative", top: "-50px" }}>Goods and Service Tax Practical Works</Text>
          <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>This module explains about the GST actual filing and routine procedures that are common in every company.</Text>
          {readMore.module5 && <>
            <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              This module focuses on six (6) areas – E-way bill generation, E-Invoicing, GST liability computation, GST payments, GSTR1B.
            </Text>
            <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              This module offers simulated GST site environment where users can do the work.
            </Text>
            <Text className={text_justify} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              Total 100 transactions would be required to be done across all nine categories of work.
            </Text>
            <Flex gap={4} style={{ color: "#ffff", position: "relative", top: "-50px" }}>
              <Box  >
                <Text>Approx. 35 hours to complete</Text>

              </Box>
              <Box  >
                <Text bold="bold" style={{ textDecoration: "line-through", color: "red" }}> Rs 5000</Text>
                <Text bold="bold">  Rs 1500</Text>
                <br />
              </Box>
            </Flex>
          </>}

          {!readMore.module5 ? <Button variant="reversed" onClick={(e) => {
            e.preventDefault();
            setReadMore({ ...readMore, module5: !readMore.module5 })
          }}
            style={{ position: "relative", top: "-40px" }}
          >Read more</Button> :
            <Button variant="reversed" style={{ position: "relative", top: "-40px" }} onClick={(e) => {
              e.preventDefault();
              setReadMore({ ...readMore, module5: !readMore.module5 })
            }}>Read Less</Button>}
        </ModuleCard>
        <ModuleCard style={{ background: "#FFF9E3" }}>
          <StaticImage src="../images/student_registration/flow.png" alt="Module5" />
        </ModuleCard>
        <ModuleCard style={{ background: "linear-gradient(151.1deg, #001A57 28.85%, #03A1E2 143.73%)", borderRadius: "7px", marginTop: "5rem" }}>

          <Video
            videoSrcURL="https://storage.googleapis.com/skillcdn/help/Demo_Video_Bizzlab.mp4"
            videoTitle="Official Music Video on YouTube"
          />


        </ModuleCard>
        <ModuleCard style={{ background: "#FFF9E3", borderRadius: "6px", padding: "15px", marginTop: "2rem" }}>
          <CardHeader >
            <Text style={{ background: "#F8EDC9" }}>
              Note to Remember
            </Text>

          </CardHeader>
          <Flex gap={4}>
            <Box >
              <Text>  Registration last date:</Text>
            </Box>
            <Box>
              {r_date && <Text>{r_date}</Text>}
            </Box>
          </Flex>
          <Flex gap={4} style={{ alignItems: "flex-start" }}>
            <Box >
              <Text>  Orientation date:</Text>
            </Box>
            <Box>
              {o_date && <Text> {o_date}</Text>}
            </Box>
          </Flex>
          <Flex gap={4}>
            <Box >
              <Text>  Programme Dean:</Text>
            </Box>
            <Box>
              <Text> College Principal</Text>
            </Box>
          </Flex>
          <Flex gap={4}>
            <Box >
              <Text>  Co-ordinators:</Text>
            </Box>
            <Box>
              <Text> BizzLab team</Text>
            </Box>
          </Flex>
        </ModuleCard>
        <div style={{
          background: "#FFFFFF",
          boxShadow: "0px 10px 20px rgba(41, 41, 42, 0.07)",
          borderradius: "4px",
          padding: ".8rem"
        }}>
          <form onSubmit={submithandler}>
            <MediumBoldText  >Registration confirmation form:</MediumBoldText>
            <div><label>Student Name</label></div>
            <div>
              <input
                onChange={handleChange}
                name="name"
                maxlength="100"
                value={formvalues.name}
                type="text" placeholder="Ex: Demo" style={{ width: "100%", marginTop: ".5rem", padding: ".4rem", borderRadius: "5px", border: "1px solid grey" }} />
              <Text bold="bold" style={{ color: "red" }}>{formErrors.name}</Text>
            </div>
            <div><label>Mobile Number</label></div>
            <div><input type="text" placeholder="7894562389"
              onChange={handleChange}
              name="phone"
              maxlength="15"
              value={formvalues.phone}
              style={{ width: "100%", marginTop: ".5rem", padding: ".4rem", borderRadius: "5px", border: "1px solid grey" }} />
              <Text bold="bold" style={{ color: "red" }}>{formErrors.phone}</Text>
            </div>
            <div><label>Email</label></div>
            <div><input onChange={handleChange}
              name="email"
              maxlength="100"
              value={formvalues.email}
              type="text" placeholder="youremail@mail.com" style={{ width: "100%", marginTop: ".5rem", padding: ".4rem", borderRadius: "5px", border: "1px solid grey" }} />
              <Text bold="bold" style={{ color: "red" }}>{formErrors.email}</Text>
            </div>

            <div><label>Class</label></div>
            <div><input
              onChange={handleChange}
              name="course"
              maxlength="100"
              value={formvalues.course}
              type="text" placeholder="B.com, M.com, BBA, MBA"
              style={{ width: "100%", marginTop: ".5rem", padding: ".4rem", borderRadius: "5px", border: "1px solid grey" }} />
              <Text bold="bold" style={{ color: "red" }}>{formErrors.course}</Text>
            </div>
            <div><label>Semister</label></div>
            <div><input
              onChange={handleChange}
              name="semister"
              maxlength="100"
              value={formvalues.semister}
              type="text" placeholder="I / II / III / IV / V" style={{ width: "100%", marginTop: ".5rem", padding: ".4rem", borderRadius: "5px", border: "1px solid grey" }} />
              <Text bold="bold" style={{ color: "red" }}>{formErrors.semister}</Text>
            </div>
            {/* <div><label> College</label></div>
            <div><input onChange={handleChange}
              name="college"
              maxlength="100"
              value={formvalues.College}
              type="text" placeholder="College" style={{ width: "100%", marginTop: ".5rem", padding: ".4rem", borderRadius: "5px", border: "1px solid grey" }} />
              <Text bold="bold" style={{ color: "red" }}>{formErrors.college}</Text>
            </div> */}
            <div><label> Payment Reference No and Date of Payment </label></div>
            <div>
              <textarea name="message" maxLength={500} value={formvalues.message} cols="1" rows="3" onChange={handleChange}
                style={{ width: "100%", marginTop: ".5rem", padding: ".4rem", borderRadius: "5px", border: "1px solid grey" }}>

              </textarea>

            </div>

            <Text>Confirmation of Modules:</Text>

            <Flex style={{ background: "#F7EFF8" }}>
              <Box >
                <FlexList >
                  <li>
                    <input type="radio" name="module_opted"
                      onChange={handleChange}
                      value="Module 1"
                    />
                  </li>
                  <li><Text bold="bold"> Only Module – I</Text></li>

                </FlexList>
              </Box>
              <Box style={{ FontSize: "14px" }}>
                <FlexList >
                  <li><Text bold="bold" style={{ textDecoration: "line-through", color: "red" }}> Rs  {single_module_actual_fee ? single_module_actual_fee : "5000"}</Text></li>
                  <li><Text bold="bold">  Rs {single_module_discounted_fee ? single_module_discounted_fee : "1500"}</Text></li>
                </FlexList>
              </Box>
            </Flex>
            <Flex gap={4}>&nbsp;</Flex>
            <Flex style={{ background: "#F7EFF8" }}>
              <Box style={{ FontSize: "14px" }}>
                <FlexList >
                  <li>
                    <input type="radio" name="module_opted"
                      onChange={handleChange}

                      value="All Modules" />
                  </li>
                  <li><Text bold="bold"> All Five Modules</Text></li>

                </FlexList>
              </Box>
              <Box style={{ FontSize: "14px" }}>
                <FlexList >
                  <li><Text bold="bold" style={{ textDecoration: "line-through", color: "red" }}> Rs {all_modules_fee ? all_modules_fee : "25000"}</Text></li>
                  <li><Text bold="bold"> Rs  {all_modules_discounted_fee ? all_modules_discounted_fee : "5000"}</Text></li>
                </FlexList>
              </Box>
            </Flex>
            <Text bold="bold" style={{ color: "red" }}>{formErrors.module_opted}</Text>
            <Flex gap={4}>&nbsp;</Flex>
            <Button variant="feed_back" type="submit" onClick={submithandler} style={{ width: "100%", justifyContent: "center" }}>Submit</Button>
            {issubmit && <ReactModal
              isOpen={issubmit}
              size="sm"
              onRequestClose={issubmit}
              contentLabel="Success"
              style={{ height: "50% !important" }}
            >
              <h2>Registered Successfully</h2>
              <Button variant="feed_back" onClick={() => setIssubmit(false)}>Close</Button>
            </ReactModal>
            }
          </form>

        </div>
      </Container>
    </Layout >
  )
}
export default StudentRegistration
