import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import Head from "./head"
import "../styles-sans.css";
import UserProvider from "./contextProvider/userProvider";
// import { QueryParamProvider } from "use-query-params";
export default function LayoutRegistration(props) {
  return (
    <UserProvider>
      {/* <QueryParamProvider> */}
        <>
          <Head {...props} />


          {props.children}

        </>
      {/* </QueryParamProvider> */}
    </UserProvider>
  )
}
